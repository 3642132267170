import { ButtonContainer, MessageDiv } from '../../styles/formularz';
import React, { useEffect, useRef, useState } from 'react';
import { StyledArticle, StyledButton } from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2 } from '../../styles/common';
import { navigate } from 'gatsby';
import { useAuth } from '../../hooks/useAuth';
import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
		title='Zalogowany pracownik'
		description='Dane zalogowanego użytkownika.'
	/>);


const isBrowser = () => typeof window !== 'undefined';

const ZalogowanyPage = () => {
	const auth = isBrowser()
		? useAuth()
		: { user: '', role: '', verifyUser: () => {}, signOut: () => {} };

	useEffect(() => {
		if (!auth.user) {
			navigate('/konto/logowanie/');
		}
	}, [auth.user]);

	if (!auth.user) {
		return null;
	}

	let role;
	switch (auth.role) {
		case 'tender':
			role = 'przetargi';
			break;
		case 'admin':
			role = 'administratorzy';
			break;
		default:
			role = 'użytkownicy';
	}

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Zalogowany
				</StyledH2>
				<MessageDiv>
					Użytkownik: {auth.user}
					<br />
					Grupa: {role}
					<ButtonContainer>
						<StyledButton type="button" onClick={auth.signOut}>
							Wyloguj
						</StyledButton>
					</ButtonContainer>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
};

export default ZalogowanyPage;
